.fc .fc-bg-event.osallistujien-maara {
  opacity: 0.9;
}

.fc .fc-bg-event.osallistujien-maara .fc-event-title {
  font-style: normal;
}

.fc .fc-highlight {
  background: none;
}

.fc .valittu-paiva {
  border: 3px solid red;
}

.fc .fc-toolbar {
  text-transform: uppercase;
}

.fc-toolbar-chunk {
  display: inline-flex;
  gap: 10px;
  flex-wrap: wrap;
  align-items: stretch;
}

/* Yliajaa itsepäisen tyylin joka rikkoo toolbarin rivityksen */
.fc-direction-ltr .fc-toolbar > * > :not(:first-child) {
  margin-left: 0;
}

.fc .fc-toolbar button.fc-button {
  border-radius: 0;
  height: 44px;
  text-transform: uppercase;
}

.fc-prev-button,
.fc-next-button {
  width: 44px;
}

.fc-button.fc-today-button {
  width: 88px;
}

.fc-daygrid-day:not(.fc-day-disabled) {
  cursor: pointer;
}

@media screen and (max-width: 1080px) {
  .fc {
    font-size: 0.766em;
  }
}
